.branding-hero {
    margin: 1.5rem;
    position: relative;
    height: 50rem;
    background-size: cover;
    background-image: linear-gradient(to bottom, transparent , black);
}
.spa-branding__logo {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 20rem;
    text-align: center;
    padding: 1rem;
    /* box-shadow: 0rem 2rem 2rem rgba(0,0,0,.5); */
}
.spa-branding__logo--dark {
    background-color: rgba(0,0,0,.5);
}
.spa-branding__logo--dark img {
    max-width: 15rem;
}
.spa-branding__logo--light {
    background-color: rgba(255,255,255,.75);
}
.spa-branding__logo--light img {
    max-width: 15rem;
}
.branding-hero__buttons {
    text-align: center;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform:translateX(-50%);
    width: 100%;
}
.branding-hero__buttons button:not(:last-child) {
    margin-right: 1rem;
}
.branding-hero__buttons button {
    width: 30rem;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, .3);
}

@media only screen and (max-width: 600px) {
    .branding-hero__buttons button:not(:last-child) {
        margin-right: 0;
    }
}
@media only screen and (max-width: 1000px) {
    .branding-hero {
        height: 39rem;
    }
}