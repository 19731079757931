* {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit; }
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit; }
a {
  cursor: pointer;
}
html {
  font-size: 62.5%; 
}
body {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  box-sizing: border-box;
  background: #F7F8F4;
  font-size: 1.6rem;
  padding:0;
}
.main {
  padding-bottom: 7rem;
}
h1 {
  font-size: 5rem;
  margin: 2rem;
  text-align: center;
}
h2 {
  font-size: 4rem;
  text-align: center;
  margin: 1.6rem;
}
.form-control {
  font-size: 1.6rem;
}
/* Fixes for Slider */
.aws-sld__bullets {
  position: relative;
  bottom: 0px;
}
.aws-sld--active {
  z-index: -999;
  transform: translate3d(0, 0, 0);
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
.btn {
  font-size: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-transform: uppercase;
  transition: all .3s;
}
.btn-primary, .btn-primary:link, .btn-primary:visited {
  color: #fff;
  background-color: #3F3F3F;
  border-color: #3F3F3F;
  border-radius: 0px;
  margin-bottom: 1rem;
}
.btn-primary:hover {
  background-color: #a39375;
  border-color: #a39375;
  color: #fff;
  box-shadow: 0rem .25rem 1rem rgba(0,0,0,.2);
  transform: translateY(-2px);
}
.btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
  transform: translateY(1px);
  background-color: #000;
  border-color: #a39375;
}
.btn-primary:focus {
  box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
}
.btn-secondary, .btn-secondary:link, .btn-secondary:visited {
  color: #fff;
  background-color: #717171;
  border-color: #717171;
  border-radius: 0px;
  margin-bottom: 1rem;
}
.btn-secondary:hover {
  background-color: #a39375;
  border-color: #a39375;
  color: #fff;
  box-shadow: 0rem .25rem 1rem rgba(0,0,0,.2);
  transform: translateY(-2px);
}
.btn-secondary:active, .btn-secondary:not(:disabled):not(.disabled):active {
  box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
  transform: translateY(1px);
  background-color: #000;
  border-color: #a39375;
}
.btn-secondary:focus {
  box-shadow: 0rem 0rem 0rem rgba(0,0,0,.2);
}
.map-wrapper {
  position: relative;
  display: block;
  height: 100vh;
  width: 100vw;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.modal-title {
  font-size: 2rem;
}
.modal-header .close {
  font-size: 3rem;
}
.form-group label {
  font-size: 1.5rem;
}
fieldset {
  border: .2rem solid #ededed;
  padding: 2rem;
  margin: 3rem 0rem;
  background-image: radial-gradient(transparent,#f2f2f2);
}
fieldset legend {
  width: auto;
  padding: .5rem 1rem;
  font-size: 2rem;
  font-weight: 600;
  background: #fff;
  border: .2rem solid #ededed;
}
.form-control {
  font-size: 2rem;
}
.form-group .form-check {
  border: 1px solid #ededed;
  padding: 1rem;
  background: #fff;
}
.voucher-complete-message {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
}