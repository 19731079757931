.spa-products {
    background: #fff;
    padding: 4rem 0rem;
}
.spa-products h2 {
    text-align: center;
    margin: 4rem 0rem;
}
.product-image-wrapper {
    margin-top: 3rem;
    margin-bottom: 4rem;
    padding: .5rem;
}
.product-image-wrapper img {
    width: 100%;
}
.card-body {
      text-align: center;
}
.card-body p {
   margin: 1rem 0rem;
   display: block;
}
@media only screen and (max-width: 768px) {
    .spa-products h2 {
        margin: 2rem 0rem;
    }
}