a:hover {
    text-decoration: none;
}
.spa-info-card {
    padding: 0;
}
.spa-info-card .row {
    margin:0;
}
.spa-info-card .row [class^="col"] {
    margin-bottom: 4rem;
    padding:0;
}
.spa-map-info {
    background-color: #fff;
}
.spa-info__logo-wrapper--light {
    width: 40%;
    height: 100%;
    background: rgba(255,255,255,.75);
    text-align: center;
}
.spa-info__logo-wrapper--light img {
    width:100%;
    max-width: 15rem;
    padding: 1rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}
.spa-info__logo-wrapper--dark {
    width: 40%;
    height: 100%;
    background: rgba(0,0,0,.6);
    text-align: center;
}
.spa-info__logo-wrapper--dark img {
    width:100%;
    max-width: 15rem;
    padding: 1rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}
.spa-info--info .row [class^="col"] {
    padding: 0;
    margin: 0;
    text-align: center;
}
.spa-info__btn  {
    color: #A5A5A5;
    padding: 1.3rem;
    font-size: 1.6rem;
    border-bottom: .1rem solid #ededed;
    width: 100%;
}
.spa-info__btn:hover  {
    color: #a39375;
    cursor: pointer;
}
.spa-info__btn--icon {
    display: inline-block;
    width: 5rem; 
}
.spa-info__btn--location {
    color: #888;
    padding:1rem;
    margin: 1rem;
    background: #f1f1f1;   
}
.spa-info__btn--location svg {
    height: 2rem;
    position: relative;
    top: -.2rem;
    margin-right:.3rem;
}
.spa-info__btn--icon svg {
    height: 3rem;
    fill: #A5A5A5; 
    margin-bottom: .5rem;
    width:5rem;
}
.spa-info__btn--text {
    display: inline-block;
    width: 12rem; 
}
.spa-info__directions {
    padding-bottom: 1rem;
    display: block;
}
.spa-info__directions svg {
    height: 4rem;
    margin: 1rem;
}
.spa-info__directions .pin {
    fill: #88807D;
}
.spa-info__directions .pin:hover {
    fill: #88807D;
}
.spa-info__directions .paper {
    fill: #c6c6c6;
}
.spa-info__direction-label {
    display:inline;
    color: #a5a5a5;
    font-size: 1.35rem;
}

@media only screen and (min-width: 768px) {
    .spa-info__logo-wrapper--dark {
        width: 40%;
    }
    .spa-info__logo-wrapper--dark img {
        width: 100%;
    }
}