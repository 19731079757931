.home-layout {
    margin-top: 1.5rem;
    margin-bottom: 5rem;
}

@media only screen and (min-width: 300px) {
    .temp-promo {
        margin-top: 10px;
        width: 100%;
    }
}

@media only screen and (min-width: 1680px) {
    .temp-promo {     
        width: 95%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width: 1800px) {
    .temp-promo {     
        width: 83%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}