.spa-page {
    padding: 15px;
}
.branding-hero__buttons a.btn-primary {
    margin: .5rem;
    width: 30rem;
}

.branding-hero__buttons a.btn-primary-dynamic {
    margin: .5rem;
    width: 30rem;
}
.spa-welcome {
    text-align:center;
}
.spa-welcome h1 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.spa-welcome p {
    font-size: 2rem;
    margin-bottom: 5rem;
    padding: 0rem 7rem;
}
@media only screen and (max-width: 600px) {
    .spa-welcome p {
        font-size: 1.6rem;
        margin-bottom: 4rem;
        padding: 0rem 3rem;
    }
  }

.bottom-space {
    margin-bottom: 4rem;
}