.spa-promotion-card {
    position: relative;
    -moz-perspective: 150rem;
    perspective: 150rem;
    perspective-origin: center;
    border-radius: 2px;
    margin-bottom: 3rem;
    box-shadow: 0rem 1rem 1rem rgba(0,0,0,.1);
}
.spa-promotion-card__face1 {
    position: relative;
    width: 100%;
    border-radius: 2px;
}
.spa-promotion-card__face1 img {
    width: 100%;
}

 .spa-promotion-card__face2__promo-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
}
 .spa-promotion-card__face2 p {
     margin-top: 1rem;
     margin-bottom: 1rem;
 }
 .spa-promotion-card__face2--start-date {
    position: relative;
    display: inline-block;
    left: 0;
    width: 49%;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1rem 2rem;
    margin-right: 2% ;
    text-align: center;
 }
 .spa-promotion-card__face2--start-date span {
    margin-right: 1rem;
 }
 .spa-promotion-card__face2--end-date {
    position: relative;
    display: inline-block;
    right: 0;
    width: 49%;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1rem 2rem;
    text-align: center;
 }
 .spa-promotion-card__face2--end-date span {
    margin-right: 1rem;
 }
 .spa-promotion-card__face2--short-description {
    border-bottom: 1px solid #eee;
    font-weight: 600;
    text-align: center;
    font-size: 2.5rem;
 }
 .spa-promotion-card__face2--long-description {
    height: 22rem;
    overflow: scroll;
    text-align: left;
    padding: 0px 2rem;
 }
 .spa-promotion-card__face2 .btn-primary {
     width: 100%;
 }
 .spa-promotion-card__face2 p {
     margin: 2rem 0rem;
 }
 .spa-promotion-card__face2 ul{
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
 .spa-promotion-card__face2--t-and-c {
     padding: 1rem;
     width: 100%;
     font-size: 1rem;
     background-color: #ededed;
     text-align: center;
 }
 .spa-promotion-card__face2--t-and-c p {
     margin-top: .5rem;
 }
 @media only screen and (max-width: 767px) {
    .spa-promotion-card {
        height: auto;
        margin-bottom: 3rem;
    }
     .spa-promotion-card__face2--t-and-c {
        position: relative;
    }
}
