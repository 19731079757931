.voucher ul {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.voucher-form {
    max-width: 600px;
    padding: 2rem;
    margin: auto;
    margin-bottom: 4rem;
    background: #fff;
    box-shadow: 0rem .5rem 1rem rgba(0,0,0,.1);
    background-color: #fff;
}
.voucher-grid {
    position: relative;
    display: block;
    margin: 2rem 0rem;
    min-height: 100vh;
    width: 100%;
}
.voucher-grid .tab-content {
    float: left;
    width: 21%;
    margin: 1rem;
}

.voucher-form__image {
    position: relative;
}
.voucher-form__image img {
    width: 100%;
}
.voucher-form__image--change-button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #3F3F3F;
    padding: 1rem 1.5rem;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
}
.voucher-form__image--change-button svg {
    width: 16px;
    position: relative;
    display:inline-block;
    bottom:.2rem;
    margin-left: 1rem;
}
.voucher-form__image--change-button:hover{
    transform: translateY(-.1rem);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
}
.voucher-form__image--change-button:hover.voucher-form__image--change-button svg{
    transform: translateY(-.1rem);
}

.voucher-form__info input {
    padding: .75rem;
    margin-bottom: 1rem;
    width: 100%;
}
.voucher-form__info p {
    border: 2px dashed #ededed;
    padding: 2rem 1rem;
}
.voucher-form__proof-info {
    text-align: center;
}
.voucher-form__proof-info h2 {
    font-size: 3rem;
    margin: 2rem 0rem;
}
.voucher-form__proof-info p {
    border: 2px dashed #ededed;
    padding: 2rem 1rem;
    margin: 2rem;
}
.choose-voucher .filter-border {
    text-align: center;
    margin: 4rem 0rem;
}
.choose-voucher .filter-border a {
    border: 1px solid #808080;    
    font-size: 2rem;
    margin: .5rem;
    padding: .5rem 1rem;
    display: inline-block;
}
.choose-voucher .filter-border a:hover {
    border: 1px solid transparent;    
    background: #A5A5A5;
    color: #fff;
    margin: .5rem;
    padding: .5rem 1rem;
    box-shadow: 0rem 0rem .25rem rgba(0,0,0,0.3);
}

.choose-voucher .choose-design-image > img {
    width: 100%;
    transition: all .3s;
    margin-bottom: 2rem;
}
.choose-voucher .choose-design-image img:hover {
    transform: translateY(-.1rem) scale(1.05);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
    cursor: pointer;
}
.multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
}
.multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
    background-color: #ededed;
}
.multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #218838;
}
.multi-steps > li:before {
    content: '\f00c';
    content: '\2713;';
    content: '\10003';
    content: '\10004';
    content: '\2713';
    display: block;
    margin: 0 auto 4px;
    background-color: #fff;
    width: 36px;
    height: 36px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    border-color: #218838;
    border-radius: 50%;
}
.multi-steps > li:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #218838;
    position: absolute;
    top: 16px;
    left: 50%;
    z-index: -1;
}
.multi-steps > li:last-child:after {
    display: none;
}
.multi-steps > li.is-active:before {
    background-color: #fff;
    border-color: #218838;
}
.multi-steps > li.is-active ~ li {
    color: #808080;
}
.multi-steps > li.is-active ~ li:before {
    background-color: #ededed;
    border-color: #ededed;
}
.voucher-pay-button {
    text-align: center;
    margin-bottom: 4rem;
}
@media only screen and (max-width: 600px) {
    .voucher-grid .tab-content {
        float: left;
        width: 30%;
        margin: 1%;
    }
}