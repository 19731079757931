footer {
    background: #3F3F3F;
    text-align: center;
    padding: 3rem;
}
footer li {
    list-style: none;
    border-bottom: 1px solid #555;
}
footer li:first-child {
    border-top: 1px solid #555;
}
footer li a {
    color: #A5A5A5;
    display:block;
    padding: 1rem;
}
footer li a:hover {
    color: #a39375;
    text-decoration: none;
}
.absolute-footer {
    background:#fff;
    display: block;
    text-align: center;
    padding-top: 3rem;
}
.absolute-footer a {
    color: #42393E;
    font-size: 1.5rem;
    line-height: 2.25rem;
    display: block;
    text-transform: uppercase;
}
.absolute-footer a:hover {
    color:#a39375;
    text-decoration: none;
}
.absolute-footer svg {
    width: 10rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    fill: #a39375;
}
.absolute-footer p {
    font-size: 1.5rem;
    color: #A5A5A5;
    margin-bottom: 0;
    padding-bottom: 1rem;
}
.social-icons {
    display: inline-block;
    width: 4rem;
    margin: 2rem;
}
.social-icons svg {
    height: 4rem;
    color: #A5A5A5;
    transition: all .3s;
}
.social-icons svg:hover {
    color:#a39375;
    cursor: pointer;
    transform: scale(1.1);
}