body {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
  }
  .social-container > div {
    display: inline-block;
    margin: .5rem;
  }

  .share-buttons {
    margin-top: 2rem;
  }
  .social-container__social-icons {
    vertical-align: top;
    display: inline-block;
    margin-right: 15px;
    text-align: center;
  }
  
  .social-container__social-icons__share-count {
    margin-top: 3px;
    font-size: 12px;
  }
  
  .social-container__social-icons__share-button {
    cursor: pointer;
  }
  
  .social-container__social-icons__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  
  .social-container__social-icons__custom-icon {
    width:64px;
    height:64px;
  }