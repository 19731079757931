.open-hours > div:not(:last-child) {
    border-bottom: 1px solid #eee;
}
.open-hours-group {
    padding: 1rem;
}
.open-hours__days {
    display: inline-block;
    width: 50%;
}
.open-hours__times {
    display: inline-block;
    width: 50%;
}