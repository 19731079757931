nav {
  background: #fff;
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  text-transform: uppercase;
}
nav li {
  font-size: 1.5rem;
}
.navbar-nav li {
  position: relative;
}
.navbar-nav li a:before {
  content: ">";
  float:right;
  margin-right: 1rem;
  color: #a5a5a5;
}

.navbar {
  padding-top: 0;
}
.navbar img {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar-nav .nav-item a.nav-link {
  color: #a39375;
  font-weight: 400;
  padding: 1.5rem;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
a.navbar-brand img {
  max-width: 20rem;
  fill: #000;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='3' stroke-linecap='square' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

}
@media (min-width: 575px) {
  .navbar-nav li a:before {
    display: none;
  }
  .navbar-collapse {
    padding: 0rem;
  }
  .navbar-nav li:not(:first-child):after {
    position: absolute;
    content: '|';
    top: 50%;
    transform: translateY(-50%);
    color: #ddd;
  }
}
