.spa-packages {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #fff;
    margin: 5%;
}
.spa-packages p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 3rem;
}
