.voucher-banner-wrapper {
    width: 100%;
    position: relative;
    background-image: url("https://legacybalance.co.za/VoucherBanner/spa-voucher-banner-background.jpg");
    background-size: 20rem;
    background-repeat: repeat;
    text-align: center;
    padding-top: 7rem;
    padding-bottom: 9rem;
}
.voucher-banner-wrapper h2 {
    font-size: 3rem;
    font-family: 'lato';
    text-transform: capitalize;
    color: #636262;
}
.voucher-banner-wrapper p {
    font-size: 1.6rem;
    font-family: 'lato';
    color: #636262;
}
.voucher-box {
    position: relative;
    width: 50rem;
    display: block;
    margin: auto;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    box-shadow: 0rem .5rem 1rem rgba(0,0,0,.5);
}
.voucher-box__image img {
    width: 100%;
}
.voucher-box__info {
    position: relative;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: block;
    padding: 2rem;
    margin: 0;
    border-radius: 0 0 5px 5px;
    box-shadow: 0rem -1rem 1rem rgba(0,0,0,.1);
}
.voucher-box__info p {
    margin-bottom: 2rem;
    margin-top: 2rem;
}
@media (max-width: 768px) {
    .voucher-banner-wrapper { 
      padding:0;  
    }
    .voucher-box {
        width: 100%;
    }
}